import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"

import headerImg from "../../images/integrations/bubble/header.png"
import img1 from "../../images/integrations/bubble/1.png"
import img2 from "../../images/integrations/bubble/2.png"
import img3 from "../../images/integrations/bubble/3.png"
import img4 from "../../images/integrations/bubble/4.png"
import img5 from "../../images/integrations/bubble/5.png"
import img6 from "../../images/integrations/bubble/6.png"

export const query = graphql`
  query BubbleIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationWebStartsPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions using Bubble with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Subscriptions using Bubble with Payhere
            </h1>

            <div className="prose mt-6">
              <img src={headerImg} className="" />

              <p>
                We have been speaking to many of our users who use the no-code/
                low-code platform{" "}
                <a
                  href="https://bubble.io/"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <strong>Bubble</strong>
                </a>
                , if you haven't used it, definitely check it out! It allows
                anyone the ability to design, develop and host fully functional
                web apps. Our users have been successfully using Payhere to
                setup recurring payments in Bubble either for themselves or
                their client. They see by using Payhere that this experience is
                extremely fast and very easy to implement. Within this post we
                will show you, using a very simple project example, how Payhere
                can help get payments setup within your app quickly!
              </p>

              <h2>1. Create your plan within Payhere.</h2>

              <p>
                Within our plan builder you can choose if you want a weekly,
                monthly, or yearly recurring plan. Enter the rest of your
                details such as name, amount etc and then save the plan.
              </p>

              <img src={img1} className="" />

              <p>
                If you want to direct your customer to a certain page after a
                successful payment (thank you page, create password etc) now is
                the time to do so. Within plan creation, select advanced and you
                will see where you can enter the success URL.
              </p>

              <img src={img2} className="" />

              <p>
                You can then copy the link that we have created for you to your
                payment page.
              </p>

              <img src={img3} className="" />

              <h2>2. Link button on Bubble out to plan URL</h2>

              <p>
                Once you have created your button on Bubble right click on it
                and open the start/edit workflow Then click through on the
                following- add action, navigation, open external website and
                enter the URL in the description box as shown below.
              </p>

              <img src={img4} className="" />

              <h2>3. Payments are ready to go!</h2>

              <p>
                When your app is live and the button is pressed they will be
                taken to the Payhere payment page to enter details.
              </p>

              <img src={img5} className="" />

              <h2>4. Redirect to your selected page after payment.</h2>
              <p>
                In this case I simply created another page within Bubble that
                displays the message that I need to tell my recently signed up
                customer. This of course could be the next step within an
                onboarding flow.
              </p>

              <img src={img6} className="" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationWebStartsPage
